import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Authentication/Login";
import Error404 from "../pages/ErrorPage/Error404";
import RequireAuth from "./Authentication/RequireAuth";
import useAuth from "../hooks/useAuth";
import StudentUser from "./Users/Student";
import MentorUser from "./Users/Mentor";

export default function App() {
  const { auth } = useAuth();

  return (
    <div className="app">
      <Routes>
        {/* Element to check user type  */}
        <Route
          path="/"
          element={
            <Navigate
              to={
                auth?.userType === "Admin"
                  ? "/admin"
                  : auth?.userType === "Mentor"
                  ? "/instructor/stations"
                  : auth?.userType === "Student"
                  ? "/student"
                  : "/login"
              }
            />
          }
        />
        <Route exact path="/login" element={<Login />} />
        {/* instructor routes */}
        <Route element={<RequireAuth userType="Mentor" />}>
          {/* load the mentor main component */}
          <Route path="instructor/stations/*" element={<MentorUser />} />
        </Route>
        {/* student routes */}
        <Route element={<RequireAuth userType="Student" />}>
          <Route path="student/*" element={<StudentUser />} />
        </Route>
        {/* <Route path="admin/*" element={<AdminUser />} /> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}
