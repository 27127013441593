import { useState } from "react";
import "./Search.css";
import { TextField } from "@mui/material";
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
      color: '#6cc0e8',
      border:"#efefef"
  },
  '& .MuiInput-underline:after': {
      borderBottomColor: '#6cc0e8',
  },
  "& .Mui-disabled": {
      color: "black",
      WebkitTextFillColor: "black"
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFD1DC',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6cc0e8',
    }
  }
});

function Search(props){
    const [search,setSearch] = useState();
    function handleSearch(event){
      const value = event.target.value;
      setSearch(value);
      props.callback(value);
    }
    return (
      
        <CssTextField id="standard-search" label="Search" type="search" value={search} onChange={handleSearch} fullWidth/>
      
    )
  }

export default Search