import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter } from "react-router-dom";
import * as axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URI
axios.defaults.withCredentials = true

axios.interceptors.request.use(
  config => {
    if (!config.headers.Authorization) {
      if(localStorage.getItem("user")){

        const token = JSON.parse(localStorage.getItem("user")).accessToken;
        
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
    }

    return config;
  },
  error => Promise.reject(error)
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
);
