import "./Navbar.css";
import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { NavLink, useParams } from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import useAuth from "../../hooks/useAuth";
import LogoutIcon from "@mui/icons-material/Logout";
import EmailIcon from '@mui/icons-material/Email';
import axios from "axios"
import Toaster from "../Helpers/Toaster/Toaster";
import { deepOrange } from '@mui/material/colors';

export default function Navbar(props) {
	const { auth, setAuth } = useAuth();
	const { stationId, assignmentId } = useParams();

	const [isOpen, setIsopen] = useState(false);

	const ToggleSidebar = () => {
		isOpen === true ? setIsopen(false) : setIsopen(true);
	};

	useEffect(() => {
		try {
			if (auth) {
				if (stationId && props?.assignments && props?.assignments.length === 0) {
					const data = props?.getAssignments(stationId);
				}
			}
		} catch (error) {
			console.log(error);
			if (error?.response?.data === "Token Expired") {
				alert("Your session is expired. Please Log in again.");
				localStorage.removeItem("user");
				setAuth({});
			}
		}
	}, [stationId])

	function truncateString(str, n) {
		return str?.length > n ? str.substr(0, n - 1) + "..." : str;
	}
	function checkAnnouncePathFromURL() {
		var url = window.location.pathname;
		const arr = url.split("/");
		if (
			arr.includes("createAnnounce") ||
			arr.includes("viewAnnounce") ||
			arr.includes("announcements")
		) {
			return true;
		}
		return false;
	}
	function checkDiaryPathFromURL() {
		var url = window.location.pathname;
		const arr = url.split("/");
		if (
			arr.includes("diary")
		) {
			return true;
		}
		return false;
	}
	function checkStatsPathFromURL() {
		var url = window.location.pathname;
		const arr = url.split("/");
		if (
			arr.includes("statistics")
		) {
			return true;
		}
		return false;
	}

	function logout() {
		try {
			if (auth) {
				axios.get(`/auth/logout`)
					.then(e => {
						window.location.reload()
					}).catch(e => {
						console.log(e)
					})
			}
		} catch (error) {
			console.log(error);
			if (error?.response?.data === "Token Expired") {
				alert("Your session is expired. Please Log in again.");
			}
			localStorage.removeItem("user");
			setAuth({});
		}
	}
	return (
		<div id="navbar">
			<Toaster />
			<div className="navbar-header">
				<div className="header-logo">
					<img src="/images/BITS-Pilani-Logo-3.gif" alt="BITS Pilani" />
				</div>

				<div className="header-user" onClick={ToggleSidebar}>
					<div>
						<p className="header-user-text">
							{auth?.name ? auth.name : "Name"}
						</p>
						<p className="header-user-text">
							{auth?._id ? auth._id : "Email"}
						</p>
					</div>
					<Avatar
						className="header-user-logout"
						onClick={ToggleSidebar}
						alt="/images/google-96.png"
						src={auth?.picture ? auth.picture : ""}
						sx={{ bgcolor: deepOrange[500] }}
					/>
				</div>
			</div>
			<div className="header-bottom">
				<img src="/images/three_lines.png" alt="icon lines" />
			</div>
			<div className="navbar-wrapper-container">
				<nav className="navbar-wrapper" style={{ display: "flex" }}>
					<ul className="navbar-sortby-list">
						<ChevronRightRoundedIcon className="students-container-list-menu-toggle-icon" />
						<NavLink
							className="navbar-sortby-list-item"
							to={
								auth?.userType === "Student"
									? "/student" :
									auth?.userType === "Admin" ? "/admin"
										: "/instructor/stations"
							}
							style={{ textDecoration: "none" }}
						>
							<li>
								{auth?.userType === "Student"
									? props.stationStudent?.name
									: auth?.userType === "Admin" ? "Dashboard" :
										"Stations"
								}
							</li>
						</NavLink>
						<ChevronRightRoundedIcon
							style={{
								display:
									stationId && auth?.userType !== "Student" ? "block" : "none",
							}}
						/>

						<NavLink
							className="navbar-sortby-list-item"
							to={`/instructor/stations/${stationId}/options`}
							style={{
								textDecoration: "none",
								display:
									stationId && auth.userType !== "Student" ? "block" : "none",
							}}
						>
							<li>
								{truncateString(
									props?.stations?.find((e) => e._id === stationId)?.name,
									18
								)}
							</li>
						</NavLink>
						<ChevronRightRoundedIcon
							style={{ display: checkDiaryPathFromURL() ? "block" : "none" }}
						/>

						<NavLink
							className="navbar-sortby-list-item"
							to={
								auth?.userType === "Student"
									? `/student/${stationId}/diary`
									: `/instructor/stations/${stationId}/options/diary`
							}
							style={{
								textDecoration: "none",
								display: checkDiaryPathFromURL() ? "block" : "none",
							}}
						>
							<li>
								Weekly Diary
							</li>
						</NavLink>
						<ChevronRightRoundedIcon
							style={{ display: checkStatsPathFromURL() ? "block" : "none" }}
						/>

						<NavLink
							className="navbar-sortby-list-item"
							to={`/instructor/stations/${stationId}/options/statistics`}
							style={{
								textDecoration: "none",
								display: auth?.userType!=="Student" && checkStatsPathFromURL() ? "block" : "none",
							}}
						>
							<li>
								Statistics
							</li>
						</NavLink>
						<ChevronRightRoundedIcon
							style={{ display: checkAnnouncePathFromURL() ? "block" : "none" }}
						/>
						<NavLink
							className="navbar-sortby-list-item"
							to={
								auth?.userType === "Student"
									? `/student/announcements/${stationId}`
									: `/instructor/stations/${stationId}/options/announcements`
							}
							style={{
								textDecoration: "none",
								display: checkAnnouncePathFromURL() ? "block" : "none",
							}}
						>
							<li>{truncateString("Announcements", 18)}</li>
						</NavLink>


					</ul>
				</nav>
			</div>
			<UserProfile
				isOpen={isOpen}
				auth={auth}
				setAuth={setAuth}
				stationId={stationId}
				ToggleSidebar={ToggleSidebar}
				logout={logout}
				mentorInfo={props?.mentorId}
				student={props?.student}
			/>
		</div>
	);
}

function UserProfile({ isOpen, auth, student, ToggleSidebar, logout, mentorInfo }) {

	return (
		<React.Fragment>
			<div className={`sidebar ${isOpen === true ? "active" : ""}`}>
				<div className="sd-header">
					<Avatar className="sd-header-icon" sx={{ width: 80, height: 80, border: "2px solid white" }} />
				</div>
				<div className="sd-body">
					<div className="sd-body-header">
						{auth?.name ? auth.name : "Name"}
					</div>
					<div className="sd-body-subheader">
						{auth?._id ? auth._id : "Email"}
					</div>
					<div className="sd-body-subheader">
						{student?.station.name}
					</div>
				</div>
				{auth?.userType === "Student" ? (
					<div className="sd-footer">
						<a href={`mailto:${mentorInfo}`} style={{ textDecoration: "none", color: "black" }}>
							<div className="sd-footer-btn">
								<EmailIcon />
								<p className="sd-link">Contact Mentor</p>
							</div>
						</a>
					</div>
				) : ""}
				<div className="sd-footer">
					<div className="sd-footer-btn" onClick={logout}>
						<LogoutIcon />
						<p className="sd-link">Logout</p>
					</div>
				</div>
			</div>
			<div
				className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
				onClick={ToggleSidebar}
			>

			</div>
		</React.Fragment>
	)
}