import "./Error404.css"
function Error404() {
    return (
        <div >
            <div className="navbar-header">

                <div className="header-logo">
                    <img src="/images/BITS-Pilani-Logo-3.gif" alt="BITS Pilani" />
                </div>
            </div>
            <div className="header-bottom">
                <img src="/images/three_lines.png" alt="icon lines" />
            </div>
            <div className="error-container">

                < div className="error-image" >
                    <img src="/images/undraw_Dreamer_re_9tua.png" alt="404 Page not Found" />
                </div >
                <div className="error-content">
                    <div className="error-content-heading">404</div>
                    <div className="error-content-subheading">Sorry, we can't find that page you are looking for.</div>
                </div>
            </div>
        </div >
    )
}

export default Error404