import "./StationCard.css";
import "../StationOptions/StationOptions.css";
import "../Helpers/NoStudentSelected/NoStudentSelected.css";
import Loading from "../Helpers/Loading/Loading";
import { NavLink } from "react-router-dom";

export default function Stations(props) {
	const stations = props.stations;
	return (stations.length > 0 ?
		<div className="StationOptions-station-list-container">
			<div className="StationOptions-station-list">
				{stations.map((e) => (
					<NavLink
						key={e._id}
						to={e._id + "/options"}
						style={{ textDecoration: "none" }}
					>
						<div className="stationCard">
							<div className="stationCard-content">

								<div className="stationCard-id">
									<p>{e._id}</p>
								</div>
								<div className="stationCard-name">
									{e.name}
								</div>
								<img className="stationCard-image" src="/images/BITS-Pilani-Logo-5.png" alt="logo.png" />
							</div>
						</div>
					</NavLink>
				))}
			</div>
		</div> : <Loading />
	);
}
