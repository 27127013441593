// import "./ListContainer.css";
import { useState } from "react";
import Search from "../../../Helpers/Search/Search";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

export default function ListContainer(props) {

    const { list, activeElement, handleActiveElement, iconTrue, iconFalse, toggleLocking } = props;
    const [search, setSearch] = useState("")
    const [openList, setOpenList] = useState(true);
    return (
        <div>
            <div className={openList ? "students-container-list" : "students-container-list hide"}>
                <div className="students-container-list-options">
                    <Search callback={setSearch} />
                    <div onClick={() => { setOpenList(!openList) }} className="students-container-list-toggle">
                        {openList ? <ChevronLeftRoundedIcon className="students-container-list-toggle-icon" /> :
                            <ChevronRightRoundedIcon className="students-container-list-toggle-icon" />}
                    </div>
                </div>
                <div className="students-container-list-child">
                    {list?.filter(e => e.title?.toLowerCase().includes(search.toLowerCase()))?.map((e, index) => <ListElement
                        key={e.id || index}
                        activeElement={activeElement}
                        handleActiveElement={handleActiveElement}
                        data={e}
                        icon={e.iconValue === true ? iconTrue : iconFalse}
                        toggleLocking={toggleLocking}
                    />
                    )}
                </div>
            </div>
            <div className="students-container-list-toggle-hidden-menu" style={{ display: openList ? "none" : "block" }}>
                <div onClick={() => { setOpenList(!openList) }} className="students-container-list-toggle">
                    {openList ? <ChevronLeftRoundedIcon className="students-container-list-toggle-icon" /> :
                        <ChevronRightRoundedIcon className="students-container-list-toggle-icon" />}
                </div>
            </div>
        </div>
    );
}

function ListElement(props) {

    return (
        <div
            className={props?.activeElement === props?.data?.id ? "student-active" : "student"}
            onClick={() => props?.handleActiveElement(props?.data?.id)}
        >
            <div className="student-details-container"  >
                <div className="student-details-container-1">
                    <div className="student-name">{props.data.title}</div>
                    <div className="student-station">{props.data.body}</div>
                </div>
                <div>
                    {props?.icon}
                </div>
            </div>
        </div>
    );
}