import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import Loading from "../../Helpers/Loading/Loading";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";
import { Button } from "@mui/material";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#6cc0e8",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6cc0e8",
  },
  "& .Mui-disabled": {
    color: "black",
    WebkitTextFillColor: "black",
  },
});

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function StudentAssignment(props) {
  const { stationId } = useParams();
  const { selectedAssignment, assignments, submissions, getSubmissions } =
    props;
  const { auth, setAuth } = useAuth();
  const selectedStudent = auth?._id;
  const [assignment, setAssignment] = useState();
  const [answers, setAnswers] = useState();

  const setDefaultAnswer = async () => {
    if (assignment) {
      const data = Array(assignment.questions.length).fill("");
      setAnswers((prev) => {
        return data;
      });
    }
  };

  const getAssignment = async () => {
    try {
      if (auth) {
        if (assignments && assignments.length > 0) {
          const data = await props.assignments.filter(
            (e) => e._id === selectedAssignment
          )[0];
          setAssignment(data);
        }
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }
  };

  const getSubmission = () => {
    try {
      if (auth && submissions && submissions.length > 0) {
        const submission = submissions?.filter(
          (e) =>
            e.assignment === selectedAssignment && e.student === selectedStudent
        );
        if (submission.length === 1) {
          setAnswers(submission[0].answers.map((e) => e.answer));
        } else {
          setDefaultAnswer();
        }
      } else {
        setDefaultAnswer();
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }
  };

  const handleAnswerChange = (index, value) => {
    setAnswers(answers.map((e, i) => (i === index ? value : e)));
  };

  const submitAssignment = async () => {
    try {
      if (auth) {
        axios
          .post(`/api/submission/updateSubmission`, {
            assignment: selectedAssignment,
            answers: answers.map((e) => ({ answer: e, marks: 0 })),
          })
          .then((e) => {
            toast.success("Assignment submitted successfully");
            getSubmissions();
          })
          .catch((e) => {
            toast.error("Error: Please try again.");
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
  };
  const debouncedSubmitAssignment = debounce(submitAssignment, 300);

  useEffect(() => {
    getAssignment();
  }, [selectedAssignment, stationId]);

  useEffect(() => {
    getSubmission();
  }, [stationId, selectedStudent, assignment, submissions]);

  return assignment ? (
    <div className="student-diary">
      <div className="diary-student-profile">
        <div className="diary-student-profile-details">
          <div className="student-name">{assignment?.name}</div>
          <div className="student-station"></div>
        </div>
        <div className="diary-options">
          <div
            className="diary-options-lock-show"
            style={{ display: assignment?.isLocked ? "block" : "none" }}
          >
            {assignment?.isLocked ? (
              <LockRoundedIcon />
            ) : (
              <LockOpenRoundedIcon />
            )}
          </div>
          <Button
            style={{
              margin: "10px",
              marginLeft: "0px",
              display: assignment?.isLocked ? "none" : "block",
            }}
            variant="contained"
            onClick={debouncedSubmitAssignment}
          >
            Save
          </Button>
        </div>
      </div>
      <div className="diary-question-container">
        {answers && answers.length === 0 ? (
          <div className="student-details-empty">
            <p>Diary not submitted by the Student.</p>
          </div>
        ) : (
          assignment &&
          answers &&
          assignment?.questions.map((e, i) => (
            <div className="diary-question" key={e._id}>
              <div className="diary-question-1">{e.name}</div>
              <div className={"diary-question-2-active"}>
                <CssTextField
                  key={i}
                  id="standard-textarea"
                  label=""
                  placeholder="No answer"
                  multiline
                  fullWidth
                  variant="standard"
                  value={answers[i]}
                  onChange={(event) => {
                    handleAnswerChange(i, event.target.value);
                  }}
                  disabled={assignment?.isLocked}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  ) : (
    <Loading />
  );
}
