import {Outlet } from "react-router-dom";
import "./Home.css";
import Navbar from "../Navbar/Navbar";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import Loading from "../Helpers/Loading/Loading";
export default function Home(props) {
  let {student,userType} = props;
  const {auth} = useAuth();
  useEffect(() => {
    props.getUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userType]);
  return (
    <>{
      auth?.userType==="Student"?student!==undefined && student !== null ? <div id="home">
      <Navbar 
        stations={props.stations}
        assignments={props.assignments}
        stationStudent={props.station}
        mentorId={props.student?.mentor}
        student={props.student}
        />
      <Outlet />
  </div>:<Loading />:<div id="home">
        <Navbar 
          stations={props.stations}
          assignments={props?.assignments}
          getAssignments={props?.getAssignments}
          />
        <Outlet />
    </div>
    }</>
    
  );
}
