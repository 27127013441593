import { Route, Routes } from "react-router-dom"
import Home from "../Home/Home"
import ViewAnnounce from "../Announcements/Announcements"
import { useState,useEffect } from "react"
import useAuth from "../../hooks/useAuth"
import axios from "axios"
import StationOptions from "../StationOptions/StationOptions"
import Diary from "../Assignments/StudentAssignments/Diary";

export default function StudentUser({ }) {

    const { auth, setAuth } = useAuth();
    const [student, setStudent] = useState();

    async function getStudent() {
        try {
            if (auth) {
                const res = await axios.post("/api/student/getStudent", {
                    email: auth._id
                });
                setStudent(res.data);
            }
        } catch (error) {
            console.log(error);
            if (error.response.data === "Token Expired") {
                alert("Your session is expired. Please Log in again.");
            }
            localStorage.removeItem("user");
            setAuth({});
        }
    }

    return (
        <Routes>
            <Route
                path=""
                element={
                    <Home
                        getUser={getStudent}
                        station={student?.station}
                        student={student}
                    />
                }
            >
                <Route 
                    path=":stationId/announcements"
                    element={<ViewAnnounce />}
                />
                <Route
                    path=":stationId/diary"
                    element={
                        <Diary 
                            student={student}
                        />
                    }
                />
                <Route
                    path=""
                    element={
                        <StationOptions
                            station={student?.station?._id}
                        />
                    }
                />
            </Route>
        </Routes>
    )
}