import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "../Helpers/Loading/Loading";
import "./Scores.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#6cc0e8",
    border: "#efefef",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6cc0e8",
  },
  "& .Mui-disabled": {
    color: "black",
    WebkitTextFillColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFD1DC",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6cc0e8",
    },
  },
});
const newInfo = [
  { "name": "Quiz - 1", "maxScore": 7 },
  { "name": "Group Discussion - 1", "maxScore": 7 },
  { "name": "Diary - 1", "maxScore": 2.5 },
  { "name": "Observation - 1", "maxScore": 2.5 },
  { "name": "Seminar - 1", "maxScore": 10 },
  { "name": "Observation - 2", "maxScore": 2.5 },
  { "name": "Midsem Project Report", "maxScore": 10 },
  { "name": "Viva Voce - 1", "maxScore": 2 },
  { "name": "Diary - 2", "maxScore": 2.5 },
  { "name": "Quiz - 2", "maxScore": 8 },
  { "name": "Group Discussion - 2", "maxScore": 8 },
  { "name": "Diary - 3", "maxScore": 2.5 },
  { "name": "Observation - 3", "maxScore": 2.5 },
  { "name": "Seminar - 2", "maxScore": 10 },
  { "name": "Observation - 4", "maxScore": 2.5 },
  { "name": "Endsem Project Report", "maxScore": 15 },
  { "name": "Viva Voce - 2", "maxScore": 3 },
  { "name": "Diary - 4", "maxScore": 2.5 }
];
const columns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "fullName",
    headerName: "Full Name",
    width: 230,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
    editable: false,
  },
  {
    field: "total",
    headerName: "Total",
    width: 150,
    editable: false,
    valueGetter: (params) => {
      let total = 0;
      newInfo.forEach(info => {
        const field = info.name.toLowerCase().replace(/ /g, '');
        total += params.row[field] || 0;
      });
      return total;
    }
  },
  ...newInfo.map(info => ({
    field: info.name.toLowerCase().replace(/ /g, ''),
    headerName: info.name,
    type: "number",
    width: 200,
    editable: true,
    preProcessEditCellProps: (params) => {
      const hasError = params.props.value > info.maxScore;
      if (hasError) {
        toast.error(`Max score of ${info.maxScore} exceeded for ${info.name}`);
      }
      return { ...params.props, error: hasError };
    }
  }))
];

const grades = [
  ["A", "A-"],
  ["B", "B-"],
  ["C", "C-"],
  ["D", "NC"],
];

export default function Scores(props) {
  let { stationId } = useParams();
  // const { students} = props;

  // const [activeStudent, setActiveStudent] = useState();
  // const [score, setScore] = useState([]);
  // const [maxScore, setMaxScore] = useState({});
  // const [student, setStudent] = useState([]);
  const [students, setStudents] = useState([]);
  const [rows, setRows] = useState([]);
  const [bounds, setBounds] = useState({
    gradeBounds: {
      A: { upperBound: 0, lowerBound: 0 },
      "A-": { upperBound: 0, lowerBound: 0 },

      B: { upperBound: 0, lowerBound: 0 },

      "B-": { upperBound: 0, lowerBound: 0 },

      C: { upperBound: 0, lowerBound: 0 },

      "C-": { upperBound: 0, lowerBound: 0 },

      D: { upperBound: 0, lowerBound: 0 },
      NC: { upperBound: 0, lowerBound: 0 },
    },
  });
  const { auth, setAuth } = useAuth();

  const handleFetchRows = async (stationID) => {
    try {
      if (auth && auth?.userType !== "Student") {
        getStudentList(stationId);
        // getStudentScores(stationId);
        const newRows = students?.map((student) => {
          const scores = {};
          newInfo.forEach(info => {
            const field = info.name.toLowerCase().replace(/ /g, '');
            scores[field] = student?.scores[info.name];
          });
        
          return {
            id: student?._id,
            fullName: student?.name,
            email: student?._id,
            ...scores
          };
        });
        setRows(newRows);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }
  };

  const onCellEditCommit = async (params) => {
    const { id, field, value } = params;
    // console.log("Editing cell:", id, field, value);

    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    // console.log("Updated rows:", updatedRows);
    setRows(updatedRows);
    // setChange((prev) => !prev);
  };

  const handleSave = async () => {
    const scoresData = rows?.map(row => {
      const scores = {};
      newInfo.forEach(info => {
        const field = info.name.toLowerCase().replace(/ /g, '');
        scores[info.name] = row[field];
      });
      return {
        studentEmail: row.email,
        scores: scores
      };
    });
    let obj = {
      "scoresData": scoresData,
      "station":stationId
    }
    console.log(obj);
    // console.log(ans);
    await handleScoreSave(obj);
    // await setRows(rows);'
    // console.log(res);
  };

  const getStudentList = async (stationId) => {
    try {
      if (auth?.userType !== "Student") {
        const data = await axios.post(`/api/student/getByStation`, {
          station: stationId,
        });
        setStudents(data.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
  };

  const handleGradeSave = async () => {
    // console.log(bounds);

    // return;
    try {
      if (auth.userType !== "Student") {
        let obj = {
          station: stationId,
          gradeBounds: bounds?.gradeBounds,
        };
        let res = await axios.post("/api/grades/setGradeBounds", obj);
        if (res.status === 200) {
          toast.success("Grade Bounds Set Successfully");
        }
        // if (res.status === 400) console.log(res);
        // else
      }
    } catch (error) {
      if (error.response.data === "Invalid grade ranges") {
        toast.error("Grade ranges invalid");
      }
    }
  };

  const handleScoreSave = async (scoresData) => {
    // console.log(scoresData);
    try {
      console.log(scoresData)
      if (auth.userType !== "Student") {
        await axios.post("/api/grades/setBulkScore", scoresData);
        toast.success("Score saved");
        // return res;
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Score exceeds max scores!") {
        toast.error(error.response.data);
        return;
      }
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");

        localStorage.removeItem("user");
        setAuth({});
      }
    }
  };

  const handleFetchGrades = async (stationId) => {
    if (auth) {
      let obj = { station: stationId };
      let res = await axios.post("/api/grades/getGradeRange", obj);
      // setBounds(res.data);
      // let resObj = res.data[0].gradeBounds;
      let resObj = {
        gradeBounds: res.data[0].gradeBounds,
      };
      // console.log(res);
      if (res.status === 200) {
        setBounds(resObj);
        // toast.success("fetch success");
      }
    }
  };

  const handleDownload = async () => {
    try {
      const response = await axios.post('/api/grades/getStationReport', {
        station: stationId,
      }, {
        responseType: 'blob',
      });
  
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Report.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        toast.error('There was an error in downloading the file');
      }
    } catch (error) {
      toast.error('There was an error in downloading the file');
      console.log(error);
    }
  };
  useEffect(() => {
    handleFetchRows(stationId);
    handleFetchGrades(stationId);
    // console.log(rows);
  }, [students?.length, stationId]);

  // async function callScore() {
  //   const reqArray = ["evaluativeComponents"];
  //   let res = await axios.post("/api/extraInfo/getExtraInfo", { reqArray });
  //   let resObj = {};
  //   let maxObj = {};
  //   let name;
  //   for (var i = 0; i < res?.data[0]?.info.length; i++) {
  //     name = res?.data[0]?.info[i].name;
  //     resObj[name] = 0;
  //     maxObj[name] = res?.data[0]?.info[i].maxScore;
  //   }
  //   setMaxScore(maxObj);
  //   return resObj;
  // }

  // async function getStudent() {
  //   try {
  //     const res = await axios.post("/api/student/getStudent", {
  //       email: studentId,
  //     });
  //     setStudent(res.data);
  //     return res?.data?.scores;
  //   } catch (error) {
  //     console.log(error);
  //     if (error.response.data === "Token Expired") {
  //       alert("Your session is expired. Please Log in again.");
  //       localStorage.removeItem("user");
  //       setAuth({});
  //     }
  //   }
  // }

  // useEffect(() => {
  //   handleFetch();
  //   setActiveStudent(students.find((e) => e._id === studentId));
  // }, [students, stationId, studentId]);

  // const handleFetch = async () => {
  //   let resInit;
  //   resInit = await callScore();
  //   let resFetch;
  //   resFetch = await getStudent();
  //   resFetch && Object.keys(resFetch).length > 0
  //     ? setScore(resFetch)
  //     : setScore(resInit);
  // };

  // function handleScoreChange(name, value) {
  //   if (value >= 0) {
  //     setScore((prevState) => ({
  //       ...prevState,
  //       [name]: parseInt(value),
  //     }));
  //   }
  // }
  // async function handleSaving() {
  //   await setScore((prevState) => {
  //     var newScore = { ...prevState };
  //     for (let key in prevState) {
  //       newScore[key] = parseInt(prevState[key]);
  //     }
  //     return newScore;
  //   });
  //   const data = {
  //     studentEmail: activeStudent?._id,
  //     scores: score,
  //   };
  //   await axios
  //     .post("/api/grades/setScoreForStudent", data)
  //     .then(async (res) => {
  //       if (res.status === 200) {
  //         console.log("updated");
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       toast.error("Value out of range");
  //     });
  // }
  const handleGradeBoundChange = (gradeName, boundType, newValue) => {
    // console.log(gradeName.toString());
    setBounds((prevBounds) => ({
      ...prevBounds,
      gradeBounds: {
        ...prevBounds.gradeBounds,
        [gradeName]: {
          ...prevBounds.gradeBounds[gradeName],
          [boundType]: newValue,
        },
      },
    }));
    // console.log(bounds);
  };
  return (
    <div className="student-diary">
      {/* <div className="diary-student-profile"> */}
      {/* <div className="diary-student-profile-details">
          <div className="student-name">{activeStudent?.name}</div>
          <div className="student-station">{activeStudent?.bitsID}</div>
        </div> */}
      {/* <div className="diary-options">
          <div className="diary-options-child" onClick={handleSave}>
            Save
          </div>
        </div> */}
      {/* </div> */}
      <div className="diary-question-container">
        <h1 style={{ textAlign: "center", paddingBottom: "2rem" }}>Student Scores for Components</h1>
        {rows?.length > 0 ? (
          <>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                onCellEditCommit={onCellEditCommit}
                onCellDoubleClick={() => {}}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            </Box>
            <div className="diary-options">
              <div className="diary-options-child" onClick={handleSave}>
                Save Scores
              </div>
              
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
      <div className="diary-question-container">
        <h1 style={{ textAlign: "center", paddingBottom: "2rem" }}>Set Grade Bounds</h1>
        <Box sx={{ height: "100%", width: "100%" }}>
          {grades.map((grade, index) => (
            <Box
              display="flex"
              flex={1}
              flexDirection="row"
              alignContent="center"
              justifyContent="center"
              key={index}
              // columnGap={12}
              marginBottom={4}
              alignItems="center"
            >
              {grade[0]}
              {/* <input type="number" inputmode="numeric" style={{}}/> */}
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={bounds?.gradeBounds[grade[0]]?.lowerBound}
                onChange={(e) =>
                  handleGradeBoundChange(
                    grade[0],
                    "lowerBound",
                    parseInt(isNaN(e.target.value) ? 0 : e.target.value)
                  )
                }
                sx={{
                  marginRight: 1,
                  marginLeft: 1,
                  "@media screen and (max-width: 600px)": {
                    marginRight: "2px",
                    marginLeft: "2px",
                  },
                }}
              />
              to
              <TextField
                type="number"
                variant="outlined"
                value={bounds?.gradeBounds[grade[0]]?.upperBound}
                sx={{
                  marginRight: 10,
                  marginLeft: 1,
                  "@media screen and (max-width: 600px)": {
                    marginRight: "10px",
                    marginLeft: "2px",
                  },
                }}
                onChange={(e) =>
                  handleGradeBoundChange(
                    grade[0],
                    "upperBound",
                    parseInt(e.target.value)
                  )
                }
                size="small"
              />
              {grade[1]}
              <TextField
                variant="outlined"
                size="small"
                type="number"
                value={bounds?.gradeBounds[grade[1]]?.lowerBound}
                sx={{
                  marginRight: 1,
                  marginLeft: 1,
                  "@media screen and (max-width: 600px)": {
                    marginRight: "2px",
                    marginLeft: "2px",
                  },
                }}
                onChange={(e) =>
                  handleGradeBoundChange(
                    grade[1],
                    "lowerBound",
                    parseInt(e.target.value)
                  )
                }
              />
              to
              <TextField
                variant="outlined"
                size="small"
                type="number"
                value={bounds?.gradeBounds[grade[1]]?.upperBound}
                sx={{
                  marginLeft: 1,
                  "@media screen and (max-width: 600px)": {
                    marginLeft: "2px",
                  },
                }}
                onChange={(e) =>
                  handleGradeBoundChange(
                    grade[1],
                    "upperBound",
                    parseInt(e.target.value)
                  )
                }
              />
            </Box>
          ))}
          <div className="diary-options">
            <div className="diary-options-child" onClick={handleGradeSave}>
              Save Bounds
            </div>
            <div className="diary-options-child" onClick={handleDownload}>
                Download Grades
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}

// function GradeEntry(props) {
//   function handleChange(event) {
//     props.handleChange(props.title, event.target.value);
//   }
//   return (
//     <div className="diary-question">
//       <div className="diary-question-1">{props.name}</div>
//       <div className="diary-question-2-active">
//         <CssTextField
//           id="standard-search"
//           label="Marks"
//           type="number"
//           value={props.scores[props.title]}
//           onChange={handleChange}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 /{props.maxScores[props.title]}
//               </InputAdornment>
//             ),
//           }}
//         />
//       </div>
//       <Toaster />
//     </div>
//   );
// }
