import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#6cc0e8",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6cc0e8",
  },
  "& .Mui-disabled": {
    color: "black",
    WebkitTextFillColor: "black",
  },
});

export default function CreateAnnounce(props) {
  const { stationId } = useParams();
  const { auth, setAuth } = useAuth();
  const [heading, setHeading] = useState("");
  const [body, setBody] = useState("");

  function handleSubmit() {
    try {
      if (auth) {
        axios
          .post("/api/announcement/createAnnouncement", {
            station: stationId,
            heading: heading,
            body: body
          })
          .then((e) => {
            toast.success("Announcement posted successfully.");
            props.getAll(stationId, auth);
          })
          .catch((error) => {
            console.log(error);
            if (error.status === 403) {
              localStorage.removeItem("user");
              setAuth({});
            }
          });
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
    setHeading("");
    setBody("");
    props.setCreateAnnouncement(false);
  }

  return (
    <div className="student-diary">
      <div className="diary-student-profile announcement-popup-border-fix">
        <div className="diary-student-profile-details">
          <div className="student-name">Create Announcement</div>
          <div className="student-station">
            Please fill the following details to create the announcement.
          </div>
        </div>
        <div className="diary-options">
          <div
            className="diary-options-child"
            onClick={() => {
              handleSubmit();
              // sendMail();
            }}
          >
            Submit
          </div>
        </div>
      </div>
      <div>
        <br />

        <div className={"diary-question-container"}>
          <div className="diary-question">
            <div className="diary-question-1">Title</div>
            <div className="diary-question-2-active">
              <CssTextField
                id="standard-textarea"
                label=""
                placeholder="Enter Title"
                multiline
                fullWidth
                variant="standard"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
                required={true}
              />
            </div>
          </div>
          <div className="diary-question">
            <div className="diary-question-1">Body</div>
            <div className="diary-question-2-active">
              <CssTextField
                id="standard-textarea"
                label=""
                placeholder="Enter body"
                multiline
                fullWidth
                variant="standard"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                required={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
