import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import ListContainer from "./ListContainer/ListContainer";
import Loading from "../Helpers/Loading/Loading";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { useParams } from "react-router-dom";
import Filter from "./ListContainer/Filter";
import StudentDetails from "./StudentDetails";
import {toast} from "react-toastify"
import axios from "axios";

const filterListElements = [
	{
		id: "Total Students",
		title: "Total Students",
		body: "",
		iconValue: "true",
	}, {
		id: "Submitted",
		title: "Submitted",
		body: "",
		iconValue: "true",
	}, {
		id: "Not Submitted",
		title: "Not Submitted",
		body: "",
		iconValue: "true",
	}
]

export default function Statistics(props) {
	const { assignments, getAssignmentList, students, getStudentList } = props;
	const { stationId } = useParams()
	const { auth, setAuth } = useAuth();
	const [selectedAssignment, setSelectedAssignment] = useState("");
	const [selectedStudent, setSelectedStudent] = useState("");
	const [selectedFilter, setSelectedFilter] = useState(filterListElements[0].id);
	const [filteredStudents, setFilteredStudents] = useState(students);
	const [stats, setStats] = useState([0, 0, 0])
	const getStudents = async () => {
		try {
			if (auth) {
				if (!(students && students.length > 0 && students[0].station === stationId)) {
					getStudentList(stationId)
				}
			}
		} catch (error) {
			console.log(error);
			if (error.response.data === "Token Expired") {
				alert("Your session is expired. Please Log in again.");
				localStorage.removeItem("user");
				setAuth({});
			}
		}
	};

	const getAssignments = async () => {
		try {
			if (auth) {
				if (!(assignments && assignments.length > 0 && stationId && assignments[0]?.station === stationId)) {
					getAssignmentList(stationId)
				}
			}
		} catch (error) {
			console.log(error);
			if (error.response.data === "Token Expired") {
				alert("Your session is expired. Please Log in again.");
				localStorage.removeItem("user");
				setAuth({});
			}
		}
	}

	const filterStudents = async () => {
		if (selectedAssignment !== "") {
			const submittedByList = assignments?.find(e => e._id === selectedAssignment)?.submittedBy;
			if (selectedFilter === filterListElements[1].id) {
				const data = students.filter(e => submittedByList.includes(e._id))
				setFilteredStudents(data);
			}
			else if (selectedFilter === filterListElements[2].id) {
				const data = students.filter(e => (!submittedByList.includes(e._id)))
				setFilteredStudents(data);
			}
			else {
				setFilteredStudents(students);
			}
		}
	}

	const getStatistics = () => {
		const total = students?.length;
		const submitted = assignments?.find(e => e._id === selectedAssignment)?.submittedBy.length;
		const notSubmitted = total - submitted;
		setStats([total, submitted, notSubmitted])
	}
	const sendReminder = async () => {
		try {
			if (auth) {
				if(window.confirm("This action will send a reminder mail to all the students who have not submitted there diary for the selected week.")===true){

					const submittedByList = assignments?.find(e => e._id === selectedAssignment)?.submittedBy;
					const emailList = students.filter(e => (!submittedByList.includes(e._id))).map(e => e._id);
					axios.post("/api/assignment/sendReminder", {
					emailList:emailList
				}).then(()=>{
					toast.success("Reminder Sent.")
				}).catch(e=>{
					console.log(e);
				})
			}
			}
		} catch (error) {
			console.log(error);
			if (error?.response.data === "Token Expired") {
				alert("Your session is expired. Please Log in again.");
			}
			localStorage.removeItem("user");
		}
	}
	useEffect(() => {
		if (assignments?.length > 0 && selectedAssignment === "") {
			setSelectedAssignment(assignments[0]._id)
		}
	}, [assignments])

	useEffect(() => {
		if (filteredStudents?.length > 0) {
			setSelectedStudent(filteredStudents[0]._id)
		}
	}, [filteredStudents])

	useEffect(() => {
		getAssignments();
		getStudents();
	}, [stationId])

	useEffect(() => {
		filterStudents();
	}, [students, selectedAssignment, selectedFilter])

	useEffect(() => {
		if (assignments?.length > 0 && students?.length > 0) {
			getStatistics();
		}
	}, [assignments, students, selectedAssignment])

	return (
		<div className="students-container">
			{assignments?.length > 0 ?
				<ListContainer
					list={assignments.sort((a, b) => {
						let x = a.name.toLowerCase();
						let y = b.name.toLowerCase()
						if (x < y) return -1
						if (x > y) return 1
						return 0
					}).map(e => ({
						id: e._id,
						title: e.name,
						body: "",
						iconValue: e.isLocked
					}))}
					activeElement={selectedAssignment}
					handleActiveElement={setSelectedAssignment}
					iconTrue={<LockRoundedIcon />}
					iconFalse={<LockOpenRoundedIcon />}
					toggleLocking={true}
					getAssignmentList={getAssignmentList}
				/>
				: <Loading />
			}
			<Filter
				list={filterListElements}
				activeElement={selectedFilter}
				handleActiveElement={setSelectedFilter}
				toggleLocking={false}
				statistics={stats}
				sendReminder={sendReminder}
			/>
			{assignments?.length > 0 && filteredStudents?.length > 0 ?
				<ListContainer
					list={filteredStudents.sort((a, b) => {
						let x = a.name.toLowerCase();
						let y = b.name.toLowerCase()
						if (x < y) return -1
						if (x > y) return 1
						return 0
					}).map(e => ({
						id: e._id,
						title: e.name,
						body: e._id,
						iconValue: false
					}))}
					activeElement={selectedStudent}
					handleActiveElement={setSelectedStudent}
					toggleLocking={false}
				/>
				: (filteredStudents.length === 0 ?
					<div className="student-details-empty">
						<p>No students.</p>
					</div>
					: <Loading />
				)
			}
			{filteredStudents?.length > 0 && selectedStudent &&
				<StudentDetails
					students={students}
					selectedStudent={selectedStudent}
				/>
			}
		</div>
	);
}