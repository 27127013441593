import { useEffect, useState, Outlet } from "react";
import useAuth from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import ListContainer from "./ListContainer/ListContainer";
import Loading from "../../Helpers/Loading/Loading";
import MentorAssignment from "./MentorAssignment";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

export default function Diary(props) {
  const { auth, setAuth } = useAuth();
  const { stationId } = useParams();
  const {
    assignments,
    students,
    submissions,
    getStudentList,
    getAssignmentList,
    getSubmissionList,
  } = props;
  const [selectedAssignment, setSelectedAssignment] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");

  const getStudents = async () => {
    try {
      if (auth) {
        if (
          !(
            props?.students &&
            props.students.length > 0 &&
            props?.students[0].station === stationId
          )
        ) {
          getStudentList(stationId);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }
  };

  const getAssignments = async () => {
    try {
      if (auth) {
        if (
          !(
            assignments &&
            assignments.length > 0 &&
            stationId &&
            assignments[0]?.station === stationId
          )
        ) {
          getAssignmentList(stationId);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }
  };

  const getSubmissions = () => {
    try {
      if (
        !(
          auth &&
          submissions &&
          submissions.length > 0 &&
          stationId &&
          selectedAssignment !== "" &&
          submissions[0]?.assignment === selectedAssignment
        )
      ) {
        getSubmissionList(selectedAssignment);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }
  };

  useEffect(() => {
    if (assignments?.length > 0 && selectedAssignment === "") {
      setSelectedAssignment(assignments[0]._id);
    }
  }, [assignments]);

  useEffect(() => {
    if (students?.length > 0 && selectedStudent === "") {
      setSelectedStudent(students[0]._id);
    }
  }, [students]);

  useEffect(() => {
    getStudents();
    getAssignments();
  }, [stationId]);

  useEffect(() => {
    if (selectedAssignment !== "") {
      getSubmissions();
    }
  }, [stationId, selectedAssignment]);

  return (
    <div className="students-container">
      {assignments?.length > 0 ? (
        <ListContainer
          list={assignments
            .sort((a, b) => {
              let x = parseInt(a.name.match(/(?:Week|Diary) (\d+)/)[1]); 
              let y = parseInt(b.name.match(/(?:Week|Diary) (\d+)/)[1]); 
              return x - y; 
            })
            .map((e) => ({
              id: e._id,
              title: e.name,
              body: "",
              iconValue: e.isLocked,
            }))}
          activeElement={selectedAssignment}
          handleActiveElement={setSelectedAssignment}
          iconTrue={<LockRoundedIcon />}
          iconFalse={<LockOpenRoundedIcon />}
          toggleLocking={true}
          getAssignmentList={getAssignmentList}
        />
      ) : (
        <Loading />
      )}
      {assignments?.length > 0 && students.length > 0 ? (
        <ListContainer
          list={students
            .sort((a, b) => {
              let x = a.name.toLowerCase();
              let y = b.name.toLowerCase();
              if (x < y) return -1;
              if (x > y) return 1;
              return 0;
            })
            .map((e) => ({
              id: e._id,
              title: e.name,
              body: e._id,
              iconValue: assignments
                ?.find((g) => g._id === selectedAssignment)
                ?.submittedBy?.includes(e._id),
            }))}
          activeElement={selectedStudent}
          handleActiveElement={setSelectedStudent}
          iconTrue={<TaskAltRoundedIcon style={{ color: "#57cc57" }} />}
          iconFalse={<ErrorOutlineRoundedIcon style={{ color: "red" }} />}
          toggleLocking={false}
        />
      ) : (
        <Loading />
      )}
      <div className="students-container-details">
        <MentorAssignment
          selectedAssignment={selectedAssignment}
          selectedStudent={selectedStudent}
          assignments={assignments}
          submissions={submissions}
        />
      </div>
    </div>
  );
}
