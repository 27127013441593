import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import Loading from "../../Helpers/Loading/Loading";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#6cc0e8",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6cc0e8",
  },
  "& .Mui-disabled": {
    color: "black",
    WebkitTextFillColor: "black",
  },
});

export default function MentorAssignment(props) {
  const { stationId } = useParams();
  const {selectedAssignment, selectedStudent, assignments,submissions}= props;
  const { auth, setAuth } = useAuth();

  const [assignment, setAssignment] = useState();
  const [answers, setAnswers] = useState();
  
  const getAssignment = async () => {
    try {
      if (auth) {
        if (assignments && assignments.length > 0) {
          const data = await props.assignments.filter(
            (e) => e._id === selectedAssignment
          )[0];
          setAssignment(data);
        }
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }
  };

  const getSubmission = () => {
		try {
			if (auth && submissions && submissions.length > 0&& submissions[0]?.assignment === selectedAssignment){
        const submission = submissions?.filter(e=> e.assignment===selectedAssignment && e.student===selectedStudent)
        if(submission.length===1){
          setAnswers(submission[0].answers);
        }
        else{
          setAnswers([]);
        }
      }
      else{
        setAnswers([]);
      }
		} catch (error) {
			console.log(error);
			if (error.response.data === "Token Expired") {
				alert("Your session is expired. Please Log in again.");
				localStorage.removeItem("user");
				setAuth({});
			}
		}
	}
  useEffect(() => {
    getAssignment();
  }, [selectedAssignment, stationId,assignments]);

  useEffect(() => {
    getSubmission()
  },[stationId,selectedStudent,submissions]);

  return assignment ? (
    <div className="student-diary">
      <div className="diary-student-profile">
        <div className="diary-student-profile-details">
          <div className="student-name">{assignment?.name}</div>
          <div className="student-station">{selectedStudent}</div>
        </div>
        <div className="diary-options">
          <div
            className="diary-options-lock-show"
          >
            {assignment?.isLocked ? (
              <LockRoundedIcon />
            ) : (
              <LockOpenRoundedIcon />
            )}
          </div>
        </div>
      </div>
      <div className="diary-question-container">
        {
          answers && answers.length===0 ? 
          <div className="student-details-empty">
            <p>Diary not submitted by the Student.</p>
          </div> 
          :  
        assignment && answers &&
          assignment?.questions.map((e, i) => (
            <div className="diary-question" key={e._id}>
              <div className="diary-question-1">{e.name}</div>
              <div className={"diary-question-2-active"}>
                <CssTextField
                      key={answers[i]?._id}
                      id="standard-textarea"
                      label=""
                      placeholder="No answer"
                      multiline
                      fullWidth
                      variant="standard"
                      value={answers[i]?.answer}
                    />
              </div>
            </div>
          ))}
      </div>
    </div>
  ) : (
    <Loading />
  );
}
