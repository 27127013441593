import "./Announcements.css";
import * as React from "react";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Loading from "../Helpers/Loading/Loading";
import { toast } from "react-toastify";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Masonry } from "@mui/lab";
import CreateAnnounce from "./CreateAnnounce";

export default function Announcement(props) {
	const { stationId } = useParams();
	const [loaded, setIsLoaded] = useState(false);
	const { auth, setAuth } = useAuth();
	const [announceData, setAnnounceData] = useState();
	const [createAnnouncement, setCreateAnnouncement] = useState(false);

	const getAll = async (stationId, auth) => {
		try {
			if (auth) {
				const data = await axios.post(`/api/announcement/getAllForStation`, {
					station: stationId,
				});
				setAnnounceData(data.data.reverse());
			}
		} catch (error) {
			console.log(error);
			if (error?.response?.data === "Token Expired") {
				alert("Your session is expired. Please Log in again.");
			}
			localStorage.removeItem("user");
			setAuth({});
		}
	};
	useEffect(() => {
		getAll(stationId, auth);
		setTimeout(() => {
			setIsLoaded(true);
		}, 1000);
	}, []);
	useEffect(() => { }, [announceData]);

	return (
		<React.Fragment>
			{announceData === null || announceData === undefined ? (
				<Loading />
			) : (
				<div className="announcement-container">
					{announceData.length === 0 ? (
						<div className="student-details-empty">
							<p>No Announcements.</p>
						</div>
					) : (
						<Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={3}>
							{announceData.map((val, index) => (
								<AnnouncementCard val={val} key={index} getAll={getAll} />
							))}
						</Masonry>
					)}

					<div
						style={{ display: auth?.userType === "Student" ? "none" : "block" }}
						className="add-announcement-button"
						onClick={() => {
							setCreateAnnouncement((o) => !o);
							setIsLoaded(false);
						}}
					>
						<p>+</p>
					</div>
					<Popup
						open={createAnnouncement}
						position="center"
						modal
						nested
						contentStyle={{ padding: "0px" }}
					>
						<CreateAnnounce
							setCreateAnnouncement={setCreateAnnouncement}
							getAll={getAll}
						/>
					</Popup>
				</div>
			)}
		</React.Fragment>
	);
}

function AnnouncementCard(val, key) {
	return (
		<div className="view-announce-card-container">
			<SetCard val={val} key={key} />
		</div>
	);
}

export function SetCard(val, key, detail) {
	const { stationId } = useParams();
	const { auth, setAuth } = useAuth();
	const [popupDetail, setPopupDetail] = useState(false);

	const deleteAnnounce = async () => {
		try {
			if (auth) {
				const res = await axios
					.post("/api/announcement/deleteAnnouncement", {
						announcement: val?.val?.val?._id,
					})
					.catch((e) => {
						console.log(e);
					});
				if (res && res.status === 200) {
					await val?.val?.getAll(stationId, auth);
					toast.success("Announcement Successfully Deleted");
				}
			}
		} catch (error) {
			console.log(error);
			if (error.response.data === "Token Expired") {
				alert("Your session is expired. Please Log in again.");
				localStorage.removeItem("user");
				setAuth({});
			} else if (error.response.status === 400) {
				toast.error(error.response.data);
			}
		}
	};

	return (
		<React.Fragment>
			<div
				className="announcement-card"
				style={{ height: val?.val?.detail ? "60rem" : "none" }}
			>
				<div className="announcement-card-heading">
					{val?.val?.val?.heading}
				</div>
				<div
					className="announcement-card-body"
					style={{ height: val?.val?.detail ? "60rem" : "none" }}
				>
					<p>{val?.val?.val?.body}</p>
				</div>
				<div className="announcement-card-options">
					<div className="announcement-card-date">
						{val?.val?.val?.createdAt?.split("T")[0]}
					</div>
					<div className="announcement-card-options-2">
						<div className="announcement-icon-hover">
							<DeleteRoundedIcon
								onClick={deleteAnnounce}
								style={{
									cursor: "pointer",
									display: auth?.userType === "Student" ? "none" : "inline",
								}}
							/>
						</div>
						<div
							className="announcement-card-read-more"
							title="Click to read more"
							onClick={() => setPopupDetail((o) => !o)}
						>
							Read More
						</div>
					</div>
				</div>
			</div>

			<Popup
				open={popupDetail}
				closeOnDocumentClick={true}
				position="center"
				modal
				nested
				className="announcement-card"
				contentStyle={{ padding: "0px" }}
			>
				<div className="announcement-card-popup" style={{ maxHeight: "73vh" }}>
					<div className="announcement-card-options">
						<div className="announcement-card-date">
							{val?.val?.val?.createdAt?.split("T")[0]}
						</div>
						<div className="announcement-card-crud-options">
							<div className="announcement-icon-hover">
								<DeleteRoundedIcon
									onClick={deleteAnnounce}
									style={{
										cursor: "pointer",
										display: auth?.userType === "Student" ? "none" : "inline",
									}}
								/>
							</div>
						</div>
					</div>
					<div className="announcement-card-heading-popup">
						{val?.val?.val?.heading}
					</div>
					<div className="announcement-card-body-popup">
						{val?.val?.val?.body}
					</div>
				</div>
			</Popup>
		</React.Fragment>
	);
}
