import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import "./StudentDetails.css";

export default function StudentDetails({ students, selectedStudent }) {
  const [student, setStudent] = useState([]);

  useEffect(() => {
    setStudent(students?.find((e) => e._id === selectedStudent));
  }, [selectedStudent]);

  return (
    <div className="admin-station-container-1">
      <FormControl sx={{ m: 1, width: "100%", mt: 1, gap: "2rem" }}>
        <div className="avatar-name">
          <Avatar
            sx={{ bgcolor: deepOrange[500], width: "3.5rem", height: "3.5rem" }}
          >
            <p style={{ fontSize: "2rem" }}>
              {student?.name ? student?.name[0] : "X"}
            </p>
          </Avatar>
          <TextField
            variant="standard"
            sx={{ width: "100%" }}
            label="Student Name"
            displayEmpty
            focused
            inputProps="readOnly"
            notched={true}
            InputProps={{
              disableUnderline: true,
            }}
            value={student?.name}
          />
        </div>

        <TextField
          variant="standard"
          label="Student Email"
          displayEmpty
          focused
          inputProps="readOnly"
          notched={true}
          value={student?._id}
          InputProps={{
            disableUnderline: true, // <== added this
          }}
        />

        <TextField
          variant="standard"
          label="Station"
          displayEmpty
          focused
          inputProps="readOnly"
          notched={true}
          value={student?.station?.name}
          InputProps={{
            disableUnderline: true, // <== added this
          }}
        />

        <TextField
          variant="standard"
          label="Station ID"
          displayEmpty
          focused
          inputProps="readOnly"
          notched={true}
          value={student?.station?._id}
          InputProps={{
            disableUnderline: true, // <== added this
          }}
        />

        <TextField
          variant="standard"
          label="Student BITS ID"
          displayEmpty
          focused
          inputProps="readOnly"
          notched={true}
          value={student?.bitsID}
          InputProps={{
            disableUnderline: true, // <== added this
          }}
        />
      </FormControl>
    </div>
  );
}
