import "./Login.css";

function Login() {

  function googleLogin() {
    window.open(process.env.REACT_APP_API_URI+"/auth/google","_self")
  }

  return (
    <div className="login-container">
      <div id="login">
        <div className="login-heading">
          <div className="login-heading-image">
            <img src="/images/BITS-Pilani-Logo-3.gif" alt="Practice School" />
          </div>
          <div className="login-heading-lines">
            <img src="/images/three_lines.png" alt="lines" />
          </div>
        </div>
        <div className="login-google-oauth" onClick={googleLogin}>
          <img src="/images/google-96.png" alt="google logo" />
          <div className="login-google-oauth-text">Sign in with Google</div>
        </div>
      </div>
    </div>
  );
}

export default Login;
