// import "./ListContainer.css";
import { useState } from "react";
import Search from "../../Helpers/Search/Search";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import './Filter.css'
import { Button } from "@mui/material";

export default function Filter(props) {

    const { list, activeElement, handleActiveElement, iconTrue, iconFalse, toggleLocking, statistics, sendReminder } = props;
    const [search, setSearch] = useState("")
    const [openList, setOpenList] = useState(true);
    return (
        <div>
            <div className={openList ? "students-container-list" : "students-container-list hide"}>
                <div className="students-container-list-options">
                    {/* <Search callback={setSearch} /> */}
                    <Button
                        style={{
                            margin: "9px",
                            // marginLeft: "0px",
                        }}
                        variant="contained"
                        onClick={sendReminder}>
                        Send Reminder
                    </Button>
                    <div onClick={() => { setOpenList(!openList) }} className="students-container-list-toggle">
                        {openList ? <ChevronLeftRoundedIcon className="students-container-list-toggle-icon" /> :
                            <ChevronRightRoundedIcon className="students-container-list-toggle-icon" />}
                    </div>
                </div>
                <div className="students-container-list-child">
                    {list?.filter(e => e.title?.toLowerCase().includes(search.toLowerCase()))?.map((e, index) => <ListElement
                        key={e.id || index}
                        activeElement={activeElement}
                        handleActiveElement={handleActiveElement}
                        data={e}
                        icon={e.iconValue === true ? iconTrue : iconFalse}
                        toggleLocking={toggleLocking}
                        statistics={statistics[index]}
                    />
                    )}
                </div>
            </div>
            <div className="students-container-list-toggle-hidden-menu" style={{ display: openList ? "none" : "block" }}>
                <div onClick={() => { setOpenList(!openList) }} className="students-container-list-toggle">
                    {openList ? <ChevronLeftRoundedIcon className="students-container-list-toggle-icon" /> :
                        <ChevronRightRoundedIcon className="students-container-list-toggle-icon" />}
                </div>
            </div>
        </div>
    );
}

function ListElement(props) {

    return (
        <div
            className={props?.activeElement === props?.data?.id ? "student-active" : "student"}
            onClick={() => props?.handleActiveElement(props?.data?.id)}
        >
            <div className="student-details-container"  >
                <div className="student-details-container-1">
                    <div className="student-name">{props.data.title}</div>
                    <div className="student-station">{props.data.body}</div>
                </div>
                <div className="statistics-list-element-icon">
                    {props?.statistics}
                </div>
            </div>
        </div>
    );
}