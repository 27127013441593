import React, { useState } from "react";
import {
  Select,
  IconButton,
  Accordion,
  AccordionDetails,
  Button,
  MenuItem,
} from "@mui/material";
import { BsTrash } from "react-icons/bs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SubjectIcon from "@mui/icons-material/Subject";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { CssTextField } from "../../Announcements/CreateAnnounce";
import "./CreateQuiz.css";
import { useParams } from "react-router";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const MarksTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#6cc0e8",
    border: "#efefef",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6cc0e8",
  },
  "& .Mui-disabled": {
    color: "black",
    WebkitTextFillColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFD1DC",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6cc0e8",
    },
  },
});

const assignmentTypes = [
  "Diary Entry",
  "Quiz",
];

const CreateQuiz = (props) => {
  const { stationId } = useParams();
  const { auth, setAuth } = useAuth();

  const [assignment, setAssignment] = useState({
    station: stationId,
    name: "",
    type: "Diary Entry",
    questions: [
      {
        questionText: "",
        type: "text",
        open: true,
        required: false,
        maxScore: parseInt(0),
        questionMCQ: [""],
      },
    ],
  });

  async function handleSubmit() {
    try {
      if (auth) {
        axios
          .post("/api/assignment/createAssignment", assignment)
          .then(async (e) => {
            toast.success("Assignment created successfully.");
            console.log(e);
            props.setCreateAssignmentCount(props.createAssignmentCount + 1);
            await setAssignment({
              station: stationId,
              name: "",
              type: "Diary Entry",
              questions: [
                {
                  questionText: "",
                  type: "text",
                  open: true,
                  required: false,
                  maxScore: parseInt(0),
                },
              ],
            });
          })
          .catch((e) => {
            console.log(e);
            if (e.response.status == 400) {
              alert(e.response.data);
            }
          });
      }
    } catch (error) {
      console.log(error);
      if (error?.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
  }

  async function sendMail() {
    try {
      if (auth) {
        const mailBody = {
          subject: "Assignment created",
          text: "Assignment has been created for the student f20190270@goa.bits-pilani.ac.in",
          html: "",
          mailList: ["f20190270@goa.bits-pilani.ac.in"],
        };
        axios
          .post("/sendMail", mailBody)
          .then(async (e) => {
            toast.success("Mail sent successfully.");
            console.log(e);
          })
          .catch((e) => {
            console.log(e);
            if (e.response.status == 400) {
              alert(e.response.data);
            }
          });
      }
    } catch (error) {
      console.log(error);
      if (error?.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
  }

  function changeQuestionScore(value, i) {
    let qs = [...assignment.questions];
    qs[i].maxScore = value;
    setAssignment({ ...assignment, questions: qs });
  }

  function changeAssignmentName(value) {
    setAssignment({ ...assignment, name: value });
  }
  function changeAssignmentType(value) {
    setAssignment({ ...assignment, type: value });
  }
  function changeQuestion(text, i) {
    var newQuestion = [...assignment.questions];
    newQuestion[i].questionText = text;
    if (newQuestion[i].questionText === text)
      setAssignment({ ...assignment, questions: newQuestion });
  }

  function addQuestionType(i, type) {
    let qs = [...assignment.questions];
    qs[i].type = type;
    if (qs[i].type === type) setAssignment({ ...assignment, questions: qs });
  }

  function changeOptionValue(text, i, j) {
    var optionsQuestion = [...assignment.questions];
    optionsQuestion[i].questionMCQ[j] = text;
    if (optionsQuestion[i].questionMCQ[j] === text) {
      setAssignment({ ...assignment, questions: optionsQuestion });
    }
  }

  function removeOption(i, j) {
    var RemoveOptionQuestion = [...assignment.questions];
    var len = RemoveOptionQuestion[i].questionMCQ.length;
    RemoveOptionQuestion[i].questionMCQ.splice(j, 1);
    if (RemoveOptionQuestion[i].questionMCQ.length === 0) {
      RemoveOptionQuestion[i].questionMCQ.push("");
      if (RemoveOptionQuestion[i].questionMCQ.length > 0) {
        setAssignment({ ...assignment, questions: RemoveOptionQuestion });
      }
    } else if (RemoveOptionQuestion[i].questionMCQ.length < len) {
      setAssignment({ ...assignment, questions: RemoveOptionQuestion });
    }
  }

  function addOption(i) {
    var optionsOfQuestion = [...assignment.questions];
    var len = optionsOfQuestion[i].questionMCQ.length;
    optionsOfQuestion[i].questionMCQ.push("");
    if (optionsOfQuestion[i].questionMCQ?.length > len) {
      setAssignment({ ...assignment, questions: optionsOfQuestion });
    }
  }

  async function copyQuestion(i) {
    let qs = [...assignment.questions];
    var newQuestion = {
      questionText: qs[i].questionText,
      type: qs[i].type,
      open: qs[i].open,
      required: qs[i].required,
      maxScore: qs[i].maxScore,
      questionMCQ: qs[i].questionMCQ,
    };
    qs.push(newQuestion);
    setTimeout(() => {
      setAssignment({ ...assignment, questions: qs });
    }, 100);
  }

  async function deleteQuestion(i) {
    let qs = [...assignment.questions];
    if (assignment.questions?.length > 1) {
      qs.splice(i, 1);
    }
    if (qs.length < assignment.questions.length)
      setAssignment({ ...assignment, questions: qs });
  }

  async function addMoreQuestionField() {
    let qs = [...assignment.questions];
    qs.push({
      questionText: "",
      type: "text",
      open: true,
      required: false,
      maxScore: parseInt(0),
      questionMCQ: [""],
    });
    if (qs.length > assignment.questions.length)
      await setAssignment({ ...assignment, questions: qs });
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    var itemgg = [...assignment.questions];
    const itemF = reorder(
      itemgg,
      result.source.index,
      result.destination.index
    );
    setAssignment({ ...assignment, questions: itemF });
  }

  function questionsUI() {
    return assignment?.questions?.map((ques, i) => (
      <Draggable key={i} draggableId={i + "id"} index={i}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div>
              <div style={{ marginBottom: "0px" }}>
                <div style={{ width: "100%", marginBottom: "0px" }}>
                  <DragIndicatorIcon
                    style={{
                      transform: "rotate(-90deg)",
                      color: "#dae0e2",
                      position: "relative",
                      left: "360px",
                    }}
                    fontSize="small"
                  />
                </div>
                <div>
                  <Accordion
                    expanded={ques.open}
                    className={ques[i]?.open ? "add_border" : ""}
                  >
                    <div className="question_boxes">
                      <AccordionDetails className="add_question">
                        <div className="add_question_top">
                          {/* <input type="text" className="question" placeholder='Question' value={ques?.questionText} onChange={(e) => { changeQuestion(e.target.value, i) }} /> */}
                          <div className="question">
                            <CssTextField
                              id="standard-textarea"
                              label=""
                              placeholder="Question"
                              multiline
                              fullWidth
                              variant="standard"
                              value={ques?.questionText}
                              onChange={(e) => {
                                changeQuestion(e.target.value, i);
                              }}
                            />
                          </div>
                          {/* <CropOriginalIcon style={{ color: "#5f6368" }} /> */}
                          <Select
                            classname="select"
                            style={{
                              color: "#5f6368",
                              fontSize: "13px",
                              width: "230px",
                              height: "40px",
                              marginLeft: "10px",
                              marginRight: "10px",
                              border: "1.5px solid #f4f4f9",
                              borderRadius: "3px",
                              background: "transparent",
                            }}
                            value={ques?.type}
                            onChange={(e) => {
                              addQuestionType(i, e.target.value);
                            }}
                          >
                            <MenuItem id="text" value="text">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <SubjectIcon style={{ marginRight: "10px" }} />
                                Paragraph
                              </div>
                            </MenuItem>

                            <MenuItem id="upload" value="upload">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <UploadFileIcon
                                  style={{
                                    marginRight: "10px",
                                    color: "#70757a",
                                  }}
                                />
                                Upload
                              </div>
                            </MenuItem>
                            <MenuItem id="singleChoice" value="singleChoice">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <RadioButtonCheckedIcon
                                  style={{
                                    marginRight: "10px",
                                    color: "#70757a",
                                  }}
                                />
                                Single Choice
                              </div>
                            </MenuItem>
                          </Select>
                        </div>
                        {ques?.type === "text" ? (
                          <div className="add_question_body">
                            <CssTextField
                              id="standard-textarea"
                              label=""
                              placeholder="Your answer"
                              multiline
                              fullWidth
                              variant="standard"
                              disabled
                            />
                          </div>
                        ) : ques?.type === "upload" ? (
                          <div className="add_question_upload">
                            <Button variant="contained" disabled>
                              Upload
                            </Button>
                          </div>
                        ) : ques?.type === "singleChoice" ? (
                          <React.Fragment>
                            {ques?.questionMCQ?.map((op, j) => (
                              <div className="add_question_body" key={j}>
                                <RadioButtonUncheckedIcon color="disabled" />
                                <input
                                  type="text"
                                  className="text_input mcq-option"
                                  placeholder="Option"
                                  value={ques?.questionMCQ[j]}
                                  onChange={(e) => {
                                    changeOptionValue(e.target.value, i, j);
                                  }}
                                />

                                <IconButton aria-label="delete">
                                  <CloseIcon
                                    onClick={() => {
                                      removeOption(i, j);
                                    }}
                                  />
                                </IconButton>
                              </div>
                            ))}

                            <div className="add_question_body">
                              <div
                                className="add_option"
                                onClick={() => addOption(i)}
                              >
                                Add Option
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}

                        <div className="add_footer">
                          <div className="add_question_bottom_left">
                            <MarksTextField
                              id="standard-search"
                              label="Max Marks"
                              type="text"
                              value={ques.maxScore}
                              size="small"
                              onChange={(e) =>
                                changeQuestionScore(e.target.value, i)
                              }
                            />
                          </div>
                          <div className="add_question_bottom">
                            <IconButton aria-label="Copy">
                              <FilterNoneIcon onClick={() => copyQuestion(i)} />
                            </IconButton>
                            <IconButton aria-label="Delete">
                              <BsTrash onClick={() => deleteQuestion(i)} />
                            </IconButton>
                            <IconButton>
                              <AddCircleOutlineIcon
                                className="edit"
                                onClick={addMoreQuestionField}
                              />
                            </IconButton>
                          </div>
                        </div>
                      </AccordionDetails>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    ));
  }

  return (
    <div className="create-assignment-container">
      <div className="question_form">
        <br />
        <br />
        <div className="section">
          <div className="question_title_section">
            <div className="question_form_top">
              <input
                type="text"
                placeholder="Assignment Title"
                style={{ color: "black" }}
                className="question_form_top_name"
                value={assignment?.name}
                onChange={(e) => changeAssignmentName(e.target.value)}
              />
              <Select
                classname="select assignment_type"
                style={{
                  color: "#5f6368",
                  fontSize: "13px",
                  width: "230px",
                  height: "40px",
                  border: "1.5px solid #f4f4f9",
                  marginTop: "1rem",
                  borderRadius: "3px",
                  background: "transparent",
                }}
                value={assignment?.type}
                onChange={(e) => {
                  changeAssignmentType(e.target.value);
                }}
              >
                {assignmentTypes.map((type) => (
                  <MenuItem id={type} value={type}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SubjectIcon style={{ marginRight: "10px" }} />
                      {type}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {questionsUI()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            variant="contained"
            style={{ margin: "1em auto" }}
            size="large"
            onClick={() => {
              handleSubmit();
              sendMail();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateQuiz;