import { useEffect, useState } from "react";
import ListContainer from "./ListContainer/ListContainer";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import Loading from "../../Helpers/Loading/Loading";
import StudentAssignment from "./StudentAssignment";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import "../Assignments.css";

export default function Diary(props) {
  const { auth, setAuth } = useAuth();
  const { stationId } = useParams();
  const [assignments, setAssignments] = useState();
  const [submissions, setSubmissions] = useState();
  const [selectedAssignment, setSelectedAssignment] = useState("");

  const getAssignments = async () => {
    try {
      if (auth?.userType === "Student") {
        const data = await axios.post(`/api/assignment/getStationAssignment`, {
          stationID: stationId,
        });
        setAssignments(data.data);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
  };

  const getSubmissions = async () => {
    try {
      if (auth) {
        const data = await axios.post(`/api/submission/getAllForStudent`, {
          studentEmail: auth?._id,
        });
        setSubmissions(data.data);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
  };

  useEffect(() => {
    getAssignments();
    getSubmissions();
  }, []);

  useEffect(() => {
    if (assignments?.length > 0 && selectedAssignment === "") {
      setSelectedAssignment(assignments[0]._id);
    }
  }, [assignments]);

  return (
    <div className="students-container">
      {assignments?.length > 0 ? (
        <ListContainer
          list={assignments
            .sort((a, b) => {
              let x = parseInt(a.name.match(/(?:Week|Diary) (\d+)/)[1]); 
              let y = parseInt(b.name.match(/(?:Week|Diary) (\d+)/)[1]); 
              return x - y; 
            })
            .map((e) => ({
              id: e._id,
              title: e.name,
              body: "",
              iconValue: e.isLocked,
            }))}
          activeElement={selectedAssignment}
          handleActiveElement={setSelectedAssignment}
          iconTrue={<LockRoundedIcon />}
          iconFalse={<LockOpenRoundedIcon />}
          toggleLocking={false}
        />
      ) : (
        <Loading />
      )}
      <div className="students-container-details">
        <StudentAssignment
          assignments={assignments}
          submissions={submissions}
          selectedAssignment={selectedAssignment}
          getSubmissions={getSubmissions}
        />
      </div>
    </div>
  );
}
